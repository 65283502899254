.font {
	font-family: 'Raleway', sans-serif;
	background-color: #ffffff;
	padding-bottom: 2px;
}

.topcontent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 10px;
}

.title {
	font-size: 30px;
	font-weight: bold;
}

.login-link {
	color: #000;
}
.register-link {
	color: #000;
}

.search-therapist {
	padding: 0 !important;
	border: 1px solid #c6c6c6;
	border-radius: 12px;
}

.padding-top {
	padding-top: 40px;
}

.search-therapist input {
	border: 1px solid #ffffff;
	height: 56px;
	padding-left: 10px;
	color: #313341;
	font-weight: 500;
	font-size: 17px;
	border-radius: 12px 0 0 12px;
}

.first-input {
	/* border-right-color: #aaa !important; */
	width: 45%;
}

.second-input {
	/* border-right-color: rgb(255, 253, 253) !important; */
	border-radius: 0 0 0 0;
	width: 20%;
	height: 56px;
	background-color: white;
	border-top: none;
	border-bottom: none;
}

.second-input:focus {
	outline: none;
	text-decoration: none;
}

.third-input {
	border-radius: 0 0 0 0;
	width: 20%;
	height: 56px;
	background-color: white;
	border-top: none;
	border-bottom: none;
	border-left: none;
}

.third-input:focus {
	outline: none;
}

.default-select {
	border: none;
	height: 56px;
	background-color: transparent;
}

.default-select:focus {
	outline: none;
	text-decoration: none;
}

.input-button {
	width: 15%;
	height: 56px;
	background: #e51d49;
	border-radius: 0 11px 11px 0;
	color: white;
	font-size: 16px;
}

.input-button:focus {
	outline: none;
}

.side-menu .btn-close {
	height: 33px;
	width: 33px;
	-webkit-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
	box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
	-webkit-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	position: absolute;
	top: 30px;
	right: 15px;
	cursor: pointer;
}
.just-sidemenu #sidemenu_toggle {
	position: fixed;
	z-index: 999;
	/*background: #fff;*/
	right: 0;
	top: 16px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.side-menu .btn-close::before,
.side-menu .btn-close::after {
	position: absolute;
	left: 16px;
	content: ' ';
	height: 24px;
	width: 2px;
	background: #fff;
	top: 5px;
}
.side-menu .btn-close:before {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}
.side-menu .btn-close:after {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

/*side open btn*/
.sidemenu_btn {
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
	width: 36px;
	padding: 6px;
	margin-right: 1rem;
	position: absolute;
	right: 15px;
}
.sidemenu_btn > span {
	height: 2px;
	width: 100%;
	background: #212331;
	display: block;
}
.center-brand .sidemenu_btn > span,
.transparent-bg .sidemenu_btn > span,
.fixed-bottom .sidemenu_btn > span,
.just-sidemenu .toggle_white.sidemenu_btn > span,
.bg-invisible .sidemenu_btn > span {
	background: #fff;
}
.center-brand.fixedmenu .sidemenu_btn > span,
.bg-invisible.fixedmenu .sidemenu_btn > span {
	background: #212331;
}
.sidemenu_btn > span:nth-child(2) {
	margin: 4px 0;
}

.side-menu .inner-wrapper {
	padding: 3.5rem 5rem;
	height: 100%;
	position: relative;
	overflow-y: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.pul-menu.pushwrap .side-menu .inner-wrapper {
	padding: 3.5rem 2.5rem;
}
.side-menu .side-nav {
	margin-bottom: 30px;
	display: block;
}
.side-nav .navbar-nav .nav-item {
	display: block;
	margin: 10px 0;
	padding: 0 !important;
	opacity: 0;
	-webkit-transition: all 0.8s ease 500ms;
	-o-transition: all 0.8s ease 500ms;
	transition: all 0.8s ease 500ms;

	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
	transform: translateY(30px);
}
.nav-selected {
	color: black;
}
.side-nav .navbar-nav .nav-item:first-child {
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.side-nav .navbar-nav .nav-item:nth-child(2) {
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.side-nav .navbar-nav .nav-item:nth-child(3) {
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.side-nav .navbar-nav .nav-item:nth-child(4) {
	-webkit-transition-delay: 0.4s;
	-o-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
.side-nav .navbar-nav .nav-item:nth-child(5) {
	-webkit-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	transition-delay: 0.5s;
}
.side-nav .navbar-nav .nav-item:nth-child(6) {
	-webkit-transition-delay: 0.6s;
	-o-transition-delay: 0.6s;
	transition-delay: 0.6s;
}
.side-nav .navbar-nav .nav-item:nth-child(7) {
	-webkit-transition-delay: 0.7s;
	-o-transition-delay: 0.7s;
	transition-delay: 0.7s;
}
.side-nav .navbar-nav .nav-item:nth-child(8) {
	-webkit-transition-delay: 0.8s;
	-o-transition-delay: 0.8s;
	transition-delay: 0.8s;
}
.side-nav .navbar-nav .nav-item:nth-child(9) {
	-webkit-transition-delay: 0.9s;
	-o-transition-delay: 0.9s;
	transition-delay: 0.9s;
}
.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}
.side-nav .navbar-nav .nav-link {
	display: inline-table;
	color: #fff;
	padding: 2px 0 3px 0 !important;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: normal;
	position: relative;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.side-nav .navbar-nav .nav-link::after {
	content: '';
	position: absolute;
	background: #fff;
	display: inline-block;
	width: 0;
	height: 3px;
	bottom: 0;
	left: 0;
	overflow: hidden;
	-webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
	-o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
	transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.transparent-sidemenu .side-nav .navbar-nav .nav-link::after {
	display: none;
}
.side-nav .navbar-nav .nav-link:hover::after,
.side-nav .navbar-nav .nav-link:focus::after {
	width: 100%;
}
.side-nav .navbar-nav .nav-link.active {
	background: transparent;
}
.transparent-sidemenu .side-nav .navbar-nav .nav-link:hover,
.transparent-sidemenu .side-nav .navbar-nav .nav-link:focus {
	margin-left: 5px;
}
.side-menu p {
	font-size: 13px;
	margin-top: 0.5rem;
	margin-bottom: 0;
}

.navbar-nav .nav-item {
	margin: 16px 5px;
	position: relative;
	font-family: 'Raleway', sans-serif;
}

.navbar-brand > img {
	width: 100%;
}
.navbar.fixedmenu .logo-default,
.navbar .logo-scrolled {
	display: none;
}
.navbar.fixedmenu .logo-scrolled,
.navbar .logo-default {
	display: inline-block;
}

.navbar-nav .nav-item {
	margin: 16px 5px;
	position: relative;
}
.navbar-nav .nav-item:first-child {
	margin-left: 0;
}
.navbar-nav .nav-item:last-child {
	margin-right: 0;
}
.navbar-nav .nav-link {
	padding: 12px 1rem !important;
	font-weight: 400;
	font-size: 16px;
	color: #313341;
	position: relative;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	overflow: hidden;
}
.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link {
	color: #fff;
}
.center-brand.fixedmenu .navbar-nav .nav-link {
	color: #212331;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
	color: #00bcd4;
}
.fixedmenu .navbar-nav .nav-link.active,
.fixedmenu .navbar-nav .nav-link.active:hover,
.fixedmenu .navbar-nav .nav-link.active:focus,
.center-brand.fixedmenu .navbar-nav .nav-link.active {
	background: #00bcd4;
	color: #fff;
}
.center-brand .navbar-nav .nav-link:first-of-type {
	background: rgba(0, 0, 0, 0);
}

.counter-container {
	margin: auto;
}

.counter-psico {
}

.counter-psico p {
	color: #313341;
	font-size: 17px;
	font-weight: 400;
	margin: 0;
}

.dates-on-view {
	width: 100%;
}

.given-date {
	padding: 10px 0;
	width: 23%;
	text-align: center;
	font-family: 'Raleway', sans-serif;
}

.given-date p {
	font-size: 10px;
	font-weight: 600;
	color: #313341;
	padding: 2px 0;
	margin: 0;
}

.given-date h5 {
	font-size: 15px;
	font-weight: 600;
	color: #313341;
	padding: 2px 0;
	margin: 0;
}

.dates-icon-left {
	margin: auto 0 auto 10px;
}

.dates-icon-right {
	margin: auto 10px auto 0;
}

.dates-icon-left:hover {
	cursor: pointer;
}

.dates-icon-right:hover {
	cursor: pointer;
}

.dates-icon-left i {
	font-size: 32px !important;
	color: #313341;
}

.dates-icon-right i {
	font-size: 32px !important;
	color: #313341;
}

.dates-section {
	background: #fff;
	padding: 0;
	align-items: center;
	z-index: 9;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #c6c6c6;
	position: sticky;
	top: 0px;
}

.padding-vertical {
	padding-top: 35px;
	padding-bottom: 35px;
}

.padding-spinner-time {
	padding-top: 45px;
	padding-bottom: 35px;
	padding-left: 150px;
	/* padding-right: 35px; */
}

.box-warning {
	width: 100%;
	background-color: rgb(255, 94, 94);
	color: white;
	padding: 15px;
	border-radius: 10px;
}

@media (max-width: 576px) {
	.title {
		font-size: 26px;
	}
	.search-therapist input {
		font-size: 12px;
	}

	.second-input {
		font-size: 10px;
	}

	.third-input {
		font-size: 10px;
	}
	.input-button {
		font-size: 10px;
	}
	.center-brand .navbar-brand {
		width: 100px;
	}
	.side-menu {
		width: 55%;
	}
	.side-nav .navbar-nav .nav-link {
		font-size: 1.5rem;
	}
}

@media (min-width: 577px) and (max-width: 992px) {
	.title {
		font-size: 43px;
	}
	.container {
		padding-left: 35px;
	}
	.sidemenu_btn {
		margin-top: 4px;
		right: 0;
		position: absolute;
	}
	.side-menu {
		width: 300px;
	}
	.side-nav .navbar-nav .nav-item {
		margin: 5px 0;
	}
	.side-nav p {
		display: none;
	}

	.padding_bottom_acompanhamento {
		padding-bottom: 0rem;
	}
}

@media (min-width: 993px) and (max-width: 1024px) {
	.sidemenu_btn {
		position: relative;
	}
	.center-brand .navbar-brand {
		width: 100px;
	}
	.navbar-nav .nav-link {
		font-size: 13px;
	}
	.navbar-nav .nav-link-bold {
		font-size: 13px;
		font-weight: bold;
	}
}

@media (max-width: 1200px) {
	.side-menu .inner-wrapper {
		padding: 2rem 3.5rem;
	}
}
