.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.img {
  filter: grayscale(100%);
}

@media only screen and (max-width: 600px) {
  .img {
    width: 50px;
    height: 50px;
  }

  .item {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
  }
}
