.signup_content {
	background-color: #ffffff;
	min-height: 100vh;
	display: flex;
	gap: 16px;
}

.right_content {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.img {
	width: 35vw;
	max-width: 500px;
}

.left_content {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.left_content {
		width: 100%;
	}
	.right_content {
		display: none;
	}
}
