.input {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.error {
	color: rgb(235, 20, 20);
	padding-top: 5px;
	font-size: 16px;
	font-weight: 600;
}

.checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px 0 0 0;
}

.checkfield {
	background-color: transparent;
	border: 0;
}

.checkfieldText {
	font-size: 18px;
	margin: 0;
	padding: 0;
	margin-left: 10px;
	font-weight: 300;
	color: black;
}

.formcontrol {
	justify-content: center;
}

.formcontrol .newselect::before,
.formcontrol .newselect::after {
	border: 0 !important;
}

.labelselect {
	margin-left: 12px;
	margin-top: 3%;
}

.labelselect.active {
	padding: 4px;
	margin-top: 6px;
	z-index: 9;
	background-color: #ffffff;
	color: #223122 !important;
	font-weight: 600;
}

.newselect {
	border: 1px solid #aaaaaa;
	border-radius: 12px;
	padding: 8px;
	background-color: transparent;
}

.newselect svg {
	z-index: 8;
}

.newselect > div {
	background-color: transparent !important;
}
