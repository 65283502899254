.register_content {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 30px;
}

.smalltitle {
	font-size: 1.5rem;
	color: #232122;
}
