.container {
  /* width: 18%; */
  /* height: 100%; */
  margin-top: 30px;
  font-size: 15px;
  color: hsl(340, 93%, 54%);
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  /* -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
   box-shadow: 0 0 8px 0 rgba(0,0,0,0.1); */
}

.number {
  width: 35px;
  height: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
   box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
}

.number:hover {
  cursor: pointer;
  background-color: rgb(236, 236, 236);
  color: #F71A63;
  border-radius: 3px;
}

.selected {
  width: 35px;
  height: auto;
  background-color: #0070bb75;
  color: #f5f4f4;
  border-radius: 3px;

}

@media (min-width: 501px) and (max-width: 575px) {
  .container {
    max-width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .container {
    max-width: 35%;
  }
 }

 @media (max-width: 500px) {
   .container {
     max-width: 90%;
   }
 }
