.dropdown {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

label {
  font-size: 18px;
}

select {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #7070705e;
  font-size: 18px;
}

options:focus {
  background-color: #7070705e;
}
.error {
  color: rgb(235, 20, 20);
  padding-top: 5px;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (min-width: 600px) {
  .dropdown {
    width: 50%;
  }
}
