.button {
	width: 100%;
	margin-top: 5px;
	color: white;
	padding: 15px 35px;
	border-radius: 5px;
	margin-right: 5px;
	font-weight: 500;
	transition: 0.2s ease-in;
}

.button:hover {
	filter: brightness(80%);
}

@media only screen and (min-width: 600px) {
	.formButton {
		width: 100%;
	}

	.button {
		width: 50%;
		display: inline-table;
	}
}

.new_formbutton {
	border-radius: 12px;
	width: 240px;
	color: #ffffff;
	font-weight: 500;
	padding: 16px;
	background-color: #2e9be2;
}

.new_formbutton:hover {
	background-color: #2374a9;
}
