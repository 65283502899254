.therapist-name h4 {
	color: #313341;
	font-size: 24px !important;
	font-weight: 900 !important;
	font-family: 'Raleway', sans-serif;
}

.therapist-pic {
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 12px;
	max-width: 100%;
	height: auto;
	margin: auto;
	max-height: auto;
	padding: 0 !important;
}

.pic {
	display: flex;
	flex: 1;
}

.team-content {
	width: 80%;
}

.team-box {
	display: inline-block;
	max-width: 100%;
	margin: 20px 0;
	padding: 20px 0;
	background-color: white;
	border: 1px solid #eee;
	border-radius: 12px;
	display: flex;
	-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	flex-direction: column;
	justify-content: space-between;
}

.therapist-pic-wrapper {
	margin: 1em;
	padding: 0 !important;
	float: left;
	object-fit: cover;
	width: 100%;
	max-width: 235px;
	height: auto;
}

.therapist-infos {
	max-width: 100%;
	max-height: auto;
	min-width: inherit;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.therapist-approach {
	display: flex;
	/* flex-direction: row; */
	flex-wrap: wrap;
}

.approach {
	font-size: 40px;
	font-weight: lighter;
	color: gray;
}

.referencia-therapist {
	margin: auto 0 !important;
}

.prof-therapist {
	font-size: 14px !important;
	text-transform: uppercase;
	font-weight: 900 !important;
	color: #e51d49;
	padding-right: 8px;
}

.nota-therapist {
	font-size: 14px !important;
	font-weight: 600 !important;
	color: #313341;
	padding-left: 4px;
}

.nota-estrela {
	display: block;
}

.rating {
	display: block;
	padding-right: 2px;
	padding-bottom: 3px;
}

.rating img {
	height: 12px !important;
	width: 11px !important;
}

.star-container {
	display: flex;
	flex: 1;
	flex-direction: row;
	/* padding-bottom: 20px; */
}

.nota-estrela img {
	height: 14px !important;
	width: 14px !important;
}

.domini-therapist,
.about-therapist {
	font-size: 15px !important;
	font-weight: 300 !important;
	color: #313341;
}

.calendar-container {
	overflow-x: hidden;
	padding-left: 30px;
	padding-top: 10px;
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: row;
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.calendar-cell-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.calendar-cell {
	margin-bottom: 8px;
	font-size: 15px;
	/* display: flex; */
	/* flex-direction: column; */
	height: 33px;
	width: 100%;
	padding-right: 7px;
}

.calendar-cell p {
	width: 100%;
	padding: 6px 0;
	background: #eee;
	text-align: center;
	color: #313341;
	font-weight: 600;
	font-size: 1em;
	border-radius: 5px;
	cursor: pointer;
	display: block;
}

.calendar-cell p:hover {
	background: #e51d49;
	color: #fff;
	text-decoration: underline;
}

.expand-button {
	background-color: white;
	color: rgb(0, 204, 255);
	text-decoration: none;
}

.expand-button:focus {
	outline: none;
}

.testimonial-card-container {
	padding: 10px;
	padding-left: 23px;
	margin-right: 10px;
	display: flex;
	flex: 1;
	flex-direction: row;
	overflow-x: auto;
	flex-wrap: nowrap;
}

.testimonial-title {
	font-weight: bold;
	color: #313341;
	font-family: 'Raleway', sans-serif;
}

.testimonial-date {
	font-family: 'Raleway', sans-serif;
	font-size: 10px;
	color: #313343;
	text-align: end;
	align-self: flex-end;
	padding-right: 8px;
}

.header-card {
	display: flex;
	flex-direction: row;
}

.testimonial-card {
	min-width: 280px;
	border-radius: 8px;
	height: 130px;
	width: 280px;
	padding-left: 10px;
	padding-right: 5px;
	margin-right: 20px;
	line-height: 18px;
	flex-wrap: wrap;
	padding-top: 10px;
	box-shadow: -1px 1.5px 4px 2px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
	font-family: 'Open sans', sans-serif;
	color: #313343;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	padding-top: 6px;
	height: 60px;
	font-weight: lighter;
	font-size: 15px;
	/* padding-right: 5px;
  padding-left: 5px; */
}

@media (max-width: 575px) {
	.therapist-infos {
		flex-direction: column;
		align-items: center;
	}
	.therapist-pic-wrapper {
		width: 250px;
		height: 350px;
		max-width: 100%;
		min-width: 95%;
		padding-left: 5px !important;
		margin: 1em;
		padding-right: 4px !important;
		margin-top: -7px !important;
	}
	.therapist-pic {
		height: 100%;
		max-width: auto;
		max-height: auto;
	}

	.team-content {
		width: 100%;
	}

	.image img {
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: auto;
	}
	.calendar-container {
		padding-left: 20px !important;
		overflow: auto;
	}
}

/* // Medium devices (tablets, 768px and up) */
/* @media (min-width: 576px) and (max-width: 768px) {

} */

/* // Large devices (desktops, 992px and up) */
@media (min-width: 576px) and (max-width: 992px) {
	html {
		font-size: 80%;
	}
	.therapist-pic-wrapper {
		width: 50%;
		margin: 1em;
		padding: 0 !important;
		height: 50%;
		object-fit: cover;
		object-position: 100% 0;
	}
	.therapist-pic {
		width: 100%;
		height: 100%;
		max-height: 180px;
		max-width: 100%;
	}
	.pic {
		max-height: 100%;
		max-height: auto;
	}
	.expand-button {
		font-size: 15px;
	}
	.team-content {
		width: 85%;
	}
}

@media (max-width: 768px) {
	.container {
		max-width: 98%;
	}
}
