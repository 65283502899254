.field {
	width: 100%;
	font-size: 18px;
}

input {
	padding: 10px;
	border-radius: 3px;
	border: 1px solid #7070703a;
}

.input {
	display: flex;
	flex-direction: column;
}

.checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px 0 0 0;
}

.checkfield {
	background-color: blue;
}

.checkfieldText {
	font-size: 18px;
	margin: 0;
	padding: 0;
	margin-left: 10px;
	font-weight: 300;
	color: black;
}

.label {
	font-size: 18px;
	margin-top: 7px;
	color: black;
	font-weight: 400;
}

.error {
	color: rgb(235, 20, 20);
	padding-top: 5px;
	font-size: 16px;
	font-weight: 600;
}

@media only screen and (min-width: 600px) {
	.container {
		width: 1024px;
		margin: 20px auto;
	}

	.input {
		width: 100%;
	}

	.middleText {
		width: 450px;
	}
}
